.container {
  max-width: 40rem;
  padding: 0 1rem;
  margin: 1rem auto 6rem;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.postHeader {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 1rem;
}

.headerImage {
  width: 6rem;
  height: 6rem;
}

.headerHomeImage {
  width: 8rem;
  height: 8rem;
}

.backToHome {
  margin: 3rem 0 0;
}

.flexRight {
  display: flex;
  justify-content: flex-end;
}

.spaceBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}
